<template>
  <div class="container_wrap" v-if="is_authenticated">
    <el-row :gutter="20">
      <el-col :span="6">
        <div class="well_frame">
          <div class="user">
            <div class="head">
              <el-avatar :size="80" :src="imgDataUrl"></el-avatar>
              <div class="edit_layer" @click="toggleShow">
                <i class="el-icon-edit-outline"></i>
              </div>
            </div>
            <span class="text_ellipsis">{{ account_name }}</span>
          </div>
          <ul class="side_menu">
            <li v-for="(item, index) in sideMenu" :key="index" :class="{'active': item.url === activeMenu}">
              <router-link :to="item.url">{{ item.name }}</router-link>
            </li>
            <li style="margin-left:50px;"><el-button style="color:#666;" type="text" @click="logOutOpt">退出登录</el-button></li>
          </ul>
        </div>
      </el-col>
      <el-col :span="18">
        <router-view v-slot="{ Component }" v-bind="$attrs">
             <transition name="slide-fade" mode="out-in" appear>
                 <component :is="Component"></component>
             </transition>
         </router-view>
      </el-col>
    </el-row>
    <!-- 头像上传 -->
    <VueImageCropUpload field="avatar"
        @crop-upload-success="cropUploadSuccess"
        @crop-upload-fail="cropUploadFail"
        :acceptFormat="['jpg', 'jpeg', 'png']"
        v-model="show"
        :method="post"
        :width="80"
        :height="80"
        :maxSize="500"
        :imgFormat="png"
        :withCredentials="true"
        url="/api/user/manage/upload/head/"
        :params="params"
        :noSquare="true"
        ref="avatarUpload"></VueImageCropUpload>

  </div>
</template>

<script>
  // import VueImageCropUpload from 'vue-image-crop-upload';
  import VueImageCropUpload from 'components/avartar/upload-3';
  import { GetUserState, LogOutUser } from 'api/user/auth/login'
  import { routePathHandler } from 'utils/common'

  export default {
    inheritAttrs: false,
    components: {
        VueImageCropUpload
    },
    data() {
      return {
        is_authenticated: false,
        account_name: '',
        activeMenu: '',
        sideMenu:[{
          name: '账号资料',
          url: '/center/account'
        },{
          name: '我的课程',
          url: '/center/course'
        },{
          name: '我的证书',
          url: '/center/certificate'
        },{
          name: '我的实验',
          url: '/center/experiment'
        },{
          name: '我的授权码',
          url: '/center/code'
        },{
          name: '我的卡包',
          url: '/center/card/vip_card'
        },{
          name: '我的收藏',
          url: '/center/collect'
        },{
          name: '我的订单',
          url: '/center/order'
        },{
          name: '我的开票',
          url: '/center/ticket'
        }],

        show: false,
        params: {
            token: '123456798',
            name: 'avatar1'
        },
        imgDataUrl: require('/src/assets/img/logo.png')    //assets中文件需用require方式加载
      }
    },
    mounted() {
      let path = this.$route.path;
      this.pathHandler(path);
      window.newThirdBindCellphone = this.newThirdBindCellphone;
    },
    methods: {
        pathHandler(path) {
          const routePath = routePathHandler(path);
          let activePath;
          if(routePath === '/center/ticket/apply') {
            activePath = '/center/ticket'
          } else {
            activePath = routePath;
          }
          this.activeMenu = activePath;
        },
        toggleShow() {
            this.show = !this.show;
        },
        cropUploadSuccess(jsonData, field){
          this.$router.go(0);
            // this.imgDataUrl = jsonData.url;
            // this.toggleShow();
            // this.$refs.avatarUpload.setStep(1)
        },
        cropUploadFail(status, field){

        },

        logOutOpt() {
          LogOutUser().then((res) => {
            window.location.href = "/";
          })
        },
    },

    created() {
      GetUserState()
          .then((res) => {
            if (res.id) {
              this.is_authenticated = true;
              this.account_name = res.username;
              if (res.avatar) {
                this.imgDataUrl = res.avatar;
              }
            } else {
              this.$emit('update:loginVisible', true);
            }
          });
    },

    watch: {
      '$route' (to, from) {
        let path = to.path;
        this.pathHandler(path);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .user {
    padding-top:20px;
    padding-bottom:20px;
    border-bottom:2px solid #f0f0f1;
    margin-bottom:10px;
    .head {
      width:80px;
      height:80px;
      border-radius:50%;
      margin: 0 auto 20px;
      cursor: pointer;
      position:relative;
      overflow:hidden;
    }
    > span {
      display:block;
      text-align:center;
      color:#333;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .edit_layer {
      opacity: 0;
      transition: all .1s ease;
      background:rgba(0,0,0,0.5);
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      text-align: center;
      line-height: 86px;
      i {
        color:#fff;
        font-size:24px;
      }
    }
    &:hover {
      .edit_layer {
        opacity: 1;
      }
    }
  }
  .side_menu {
    margin-left:-15px;
    margin-right:-15px;
    > li {
      position:relative;
      a {
        display:block;
        padding:18px 0 18px 50px;
        color:#666;
        &:hover {
          color:#00adef;
        }
      }
      &.active {
        &:before {
          content: '';
          width:2px;
          position:absolute;
          top:0;
          left:0;
          bottom:0;
          background:#009dd9;
        }
        > a {
          background:#faf9f7;
          color:#00adef;
        }
      }
    }
  }
  :deep(.vue-image-crop-upload) {
    .vicp-wrap {
      width:420px;
      height:420px;
      &:before {
        content: '头像上传';
        line-height: 24px;
        font-size: 18px;
        color: #303133;
        display: block;
        margin-bottom: 30px;
      }
      .vicp-step1 {
        .vicp-drop-area {
          border-radius:4px;
          background-color: #f9f9f9;
          border-color: #dbdee5;
          .vicp-icon1 {
            .vicp-icon1-arrow {
              border-bottom-color:#8c939d;
            }
            .vicp-icon1-body {
              background-color:#8c939d;
            }
            .vicp-icon1-bottom {
              border-color:#8c939d;
            }
          }
        }
      }
      .vicp-step2 {
        .vicp-crop {
          .vicp-crop-left {
            .vicp-range {
              input[type=range] {
                &::-webkit-slider-thumb {
                  background-color:#00adef;
                }
                &::-moz-range-thumb {
                  background-color:#00adef;
                }
                &::-ms-thumb {
                  background-color:#00adef;
                }
                &::-webkit-slider-runnable-track {
                  background-color: rgba(0, 173, 239, 0.3);
                }
                &::-moz-range-track {
                  background-color: rgba(0, 173, 239, 0.3);
                }
                &::-ms-fill-lower {
                  background-color: rgba(0, 173, 239, 0.3);
                }
                &::-ms-fill-upper {
                  background-color: rgba(0, 173, 239, 0.15);
                }
                &:focus::-webkit-slider-runnable-track {
                  background-color: rgba(0, 173, 239, 0.5);
                }
                &:focus::-moz-range-track {
                  background-color: rgba(0, 173, 239, 0.5);
                }
                &:focus::-ms-fill-lower {
                  background-color: rgba(0, 173, 239, 0.45);
                }
                &:focus::-ms-fill-upper {
                  background-color: rgba(0, 173, 239, 0.25);
                }
              }
            }
          }
        }
      }
      .vicp-operate {
        a {
          background: #FFF;
          border: 1px solid #DCDFE6;
          color: #606266;
          &:hover {
            background:#ecf5ff;
            border-color:#c6e2ff;
            color:#409eff;
          }
        }
      }
    }
  }
  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: all 0.3s ease;
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateY(10px);
    opacity: 0;
  }
</style>
